
































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Inject } from '../../../support'
import { AnyObject, IEventbus, IModal, ModalType } from '@movecloser/front-core'
import { BaseImage } from './BaseImage.vue'
import { RouteNames } from '../routes'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ApiConnector from './map/services/api-connector'
import { Item } from './map/PoiMap.contracts'
import { ISiteService, SiteServiceType } from '../../../contexts'

@Component<PoiSingle>({
  name: 'PoiSingle',
  components: { BaseImage },
  mounted () {
    const slug = this.$route.params.slug || ''
    const connector = ApiConnector.getInstance()

    connector.getItem(slug, this.countryCode, (data: Item) => {
      this.expert = data
    })

    this.connector = connector

    const sites = this.siteService?.getActiveSiteUrls()

    if (sites.privacy) {
      this.privacyPolicyLink = sites.privacy
    } else {
      this.privacyPolicyLink = '/'
    }

    if (sites.terms) {
      this.termsLink = sites.terms
    } else {
      this.termsLink = '/'
    }
  }
})
export class PoiSingle extends Vue {
  @Inject(ModalType)
  private readonly modalConnector!: IModal

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  @Prop({
    required: true,
    type: Object
  })
  public readonly eventBus!: IEventbus

  @Prop({
    required: true,
    type: String
  })
  public readonly countryCode!: string

  public RouteNames = RouteNames

  public connector: ApiConnector | null = null

  public expert: Item | null = null

  public privacyPolicyLink = ''
  public termsLink: string = ''

  public service = {
    link: 'link'
  }

  public errors = {}
  public thxYou = ''

  public contactForm = {
    name: '',
    surname: '',
    email: '',
    phone: '',
    description: '',
    agree_1: false,
    agree_2: false
  }

  public icon (icon: string) {
    return require(`../assets/images/map/${icon}`)
  }

  public async submitContactForm (e: Event) {
    e.preventDefault()

    this.errors = []

    this.connector.contactExpert(this.expert?.slug, this.contactForm, (data: AnyObject) => {
      if (data && 'failed' in data && data.failed) {
        this.errors = data.errors
        return
      }

      this.errors = []
      this.thxYou = this.$t('modules.SelenaMa.form.thx') as string
    })
  }

  get getWebSafeUrl (): string | null {
    if (this.expert && this.expert?.web && this.expert?.web.indexOf('http') === -1) {
      return 'http://' + this.expert?.web
    }
    return this.expert?.web ?? ''
  }

  get getFBPageName (): string | null {
    if (!this.expert || !this.expert?.facebook_page) {
      return null
    }

    if (this.expert?.facebook_page.indexOf('http') === -1) {
      return this.expert.facebook_page
    }

    return this.expert.facebook_page.split('/').pop() ?? null
  }

  get coverPhoto (): string {
    let url = require('../assets/images/map/sg-bg.jpg')
    if (this.expert?.cover) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      url = `${this.expert.cover.variants.filter((item: any) => {
        return item.size === 'lg'
      })[0].url}`
    }

    if (!url) {
      url = '../images/assets/img/placeholder.jpg'
    }
    return url
  }

  get logoImg (): string | false {
    let url = null
    if (this.expert?.logo) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      url = `${this.expert.logo.variants.filter((item: any) => {
        return item.size === 'sm'
      })[0].url}`
    }
    if (!url) {
      return false
    }
    return url
  }
}

export default PoiSingle
