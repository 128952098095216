import CacheService from './cache-service'
import Singleton from './singleton'
import Axios from 'axios'

export default class ApiConnector extends Singleton {
  constructor (singleton) {
    super(singleton)

    this.baseUri = process.env.VUE_APP_SELENA_FORMS_API_BASEURL
    this.cache = CacheService.getInstance()
    this.http = Axios
  }

  __parseError (err) {
    const d = err.response?.data
    return d?.errors ? { failed: true, errors: d.errors } : []
  }

  async _get (uri, params = {}, headers = {}) {
    try {
      if (!this.cache.has(uri)) {
        const res = await this.http.get(`${this.baseUri}api/${uri}`, {
          params: params,
          headers: headers
        })
        this.cache.set(uri, res.data.data)
      }

      return this.cache.get(uri, [])
    } catch (e) {
      return this.__parseError(e)
    }
  }

  async _post (uri, body) {
    try {
      const res = await this.http.post(`${this.baseUri}api/${uri}`, body)

      return res.data.data
    } catch (e) {
      return this.__parseError(e)
    }
  }

  async createReservation (item, body, cb) {
    const data = await this._post(`experts/${item}/leads`, body)
    return cb(data)
  }

  async contactExpert (item, body, cb) {
    const data = await this._post(`experts/${item}/contact`, body)
    return cb(data)
  }

  async getExperts (countryCode, cb) {
    const data = await this._get('experts', { country_code: countryCode }, { 'Accept-Language': countryCode })
    return cb(data)
  }

  async getItem (id, countryCode, cb) {
    const data = await this._get(`experts/${id}`, {}, { 'Accept-Language': countryCode })
    return cb(data)
  }

  async getRegions (countryCode, cb) {
    const data = await this._get(`dictionaries/countries/${countryCode}/regions`, {}, { 'Accept-Language': countryCode })
    return cb(data)
  }

  async getServices (countryCode, cb) {
    const data = await this._get('tags', { type: 'expert_service', country_code: countryCode }, { 'Accept-Language': countryCode })
    return cb(data)
  }
}
