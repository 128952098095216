






import { Component } from 'vue-property-decorator'

import { EventbusType, IEventbus } from '@movecloser/front-core'

import { StructureConfigurable } from '../../../support/mixins'
import { Inject } from '../../../support'
import PoiSingleComponent from '../shared/PoiSingleComponent.vue'
import { ISiteService, SiteServiceType } from '../../../contexts'

@Component<PoiSingle>({
  name: 'PoiSingle',
  components: { PoiSingleComponent }
})
export class PoiSingle extends StructureConfigurable {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  public get activeLocale (): string {
    return this.siteService.getActiveSite().locale
  }
}

export default PoiSingle
