const singleton = Symbol('singleton')
const singletonEnforcer = Symbol('singletonEnforcer')

export default class Singleton {
  constructor (enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot construct singleton')
    }
  }

  static getInstance () {
    if (!this[singleton]) {
      this[singleton] = new this(singletonEnforcer, ...arguments)
    }

    return this[singleton]
  }
}
