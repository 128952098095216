import Singleton from './singleton'

export default class CacheService extends Singleton {
  constructor (singleton) {
    super(singleton)

    this.storage = {}
  }

  get (key, def) {
    return this.storage[key] || def
  }

  has (key) {
    return Object.keys(this.storage).includes(key)
  }

  set (key, val) {
    this.storage[key] = val
  }
}
