








import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Katarzyna Dyrda <katarzyna.dyrda@movecloser.pl> (original)
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
@Component<BaseImage>({
  name: 'BaseImage'
})

export class BaseImage extends Vue {
  /**
   * Determines alternative label for image.
   */
  @Prop({ required: false, type: String })
  public alt!: string

  /**
   * Determines selected icon to display.
   */
  @Prop({ required: false, type: String })
  public icon!: string

  /**
   * Determines selected src to display.
   */
  @Prop({ required: false, type: String })
  public src!: string

  /**
   * Determines selected img to display.
   */
  @Prop({ required: false, type: String })
  public img!: string

  @Prop({ required: false, type: Boolean, default: false })
  public isLazy!: string

  /**
   * Determines the height of the image (must be css value).
   */
  @Prop({ required: false, type: String, default: '' })
  public height!: string

  /**
   * Determines the width of the image (must be css value).
   */
  @Prop({ required: false, type: String, default: '' })
  public width!: string

  /**
   * Import icon dynamically.
   */
  public get imageSrc (): string {
    if (this.src) {
      return this.src
    }

    if (this.icon) {
      return require(`../assets/images/map/${this.icon}.svg`)
    }

    return require(`../assets/images/map/${this.img}`)
  }
}

export default BaseImage
